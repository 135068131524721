import { VisitorLogger, ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ApplicationState } from '../../types';
import { Api } from '../../api';
import { widgetDefaults } from './consts';

export interface BiLogger extends VisitorLogger {
  update: (state: ApplicationState) => void;
  initialDefaultBiLogger: (state: ApplicationState) => void;
}
export function createBiLogger({
  flowAPI,
  api,
}: {
  flowAPI: ControllerFlowAPI;
  api: Api;
}): BiLogger | undefined {
  const mapStateToDefaultBiParams = (state: ApplicationState) => {
    return {
      pageName: widgetDefaults.pageName,
    };
  };

  const { bi: viewerBiLogger } = flowAPI;
  const shouldInitializeBiLogger = viewerBiLogger && !api.isSSR();

  if (!shouldInitializeBiLogger) {
    return undefined;
  }

  const updateDefaultBiParams = (state: ApplicationState) => {
    viewerBiLogger?.updateDefaults?.(mapStateToDefaultBiParams(state));
  };

  const initialDefaultBiLogger = (state: ApplicationState) => {
    viewerBiLogger?.updateDefaults?.({
      ...widgetDefaults,
      ...mapStateToDefaultBiParams(state),
    });
  };

  return Object.assign(viewerBiLogger, {
    update: updateDefaultBiParams,
    initialDefaultBiLogger,
  }) as BiLogger;
}
