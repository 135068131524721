import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { BOOKINGS_APP_DEF_ID } from '../consts/applicationIdentities';
import { ApiContext } from './types';
import { CancelSingleArgs, cancelSingle } from './implementations/cancelSingle';
import {
  cancelWaitlist,
  CancelWaitlistArgs,
} from './implementations/cancelWaitlist';
import { LeaveResponse } from '@wix/ambassador-bookings-v1-waiting-list/types';
import { CancelGroupArgs, cancelGroup } from './implementations/cancelGroup';
import { isSSR } from './implementations/isSSR';
import { GetAggregatedInfoResponse } from '@wix/ambassador-bookings-catalog-v1-business-info/types';
import { getBusinessInfo } from './implementations/getBusinessInfo';
import {
  ListResourcesByTagNameArgs,
  listResourcesByTagName,
} from './implementations/listResourcesByTagName';
import { getEnvironmentModes } from './implementations/environmentModes';
import {
  listTransactionsForMultipleEcomOrders,
  ListTransactionsForMultipleOrdersArgs,
} from './implementations/listTransactionsForMultipleOrders';
import { ListTransactionsForMultipleOrdersResponse } from '@wix/ambassador-ecom-v1-order-transactions/types';
import {
  CancelBookingResponse,
  CancelMultiServiceBookingResponse,
} from '@wix/ambassador-bookings-v2-booking/types';
import {
  queryEcomOrders,
  QueryEcomOrdersArgs,
} from './implementations/queryOrders';
import {
  getEnrichedBookings,
  GetEnrichedBookingsArgs,
} from './implementations/getEnrichedBookings';
import { QueryOrderResponse } from '@wix/ambassador-ecom-v1-order/types';
import {
  queryBookings,
  QueryBookingsArgs,
} from './implementations/queryBookings';
import {
  queryServices,
  QueryServicesArgs,
} from './implementations/queryServices';
import {
  getGroupAllowedActions,
  GetGroupAllowedActionsArgs,
} from './implementations/getGroupAllowedActions';
import {
  getOrdersAndTransactions,
  GetOrdersAndTransactionsArgs,
} from './implementations/getOrdersAndTransactions';
import { logBi, LogBiArgs } from './implementations/logBi';
import {
  getListBookingFees,
  getListBookingFeesArgs,
} from './implementations/getListBookingFees';

export class Api {
  private readonly flowAPI: ControllerFlowAPI;

  constructor({ flowAPI }: { flowAPI: ControllerFlowAPI }) {
    this.flowAPI = flowAPI;
  }

  private getApiContext(): ApiContext {
    return {
      flowAPI: this.flowAPI,
      authorization: this.getAuthorization(),
      api: this,
    };
  }

  private getAuthorization() {
    const { controllerConfig } = this.flowAPI;
    return (
      (controllerConfig.wixCodeApi.site.getAppToken
        ? controllerConfig.wixCodeApi.site.getAppToken(BOOKINGS_APP_DEF_ID)
        : undefined) || controllerConfig.appParams.instance
    );
  }

  async cancelSingle(args: CancelSingleArgs): Promise<CancelBookingResponse> {
    return cancelSingle({
      ...args,
      ...this.getApiContext(),
    });
  }

  async cancelWaitlist(args: CancelWaitlistArgs): Promise<LeaveResponse> {
    return cancelWaitlist({
      ...args,
      ...this.getApiContext(),
    });
  }

  async cancelGroup(
    args: CancelGroupArgs,
  ): Promise<CancelMultiServiceBookingResponse> {
    return cancelGroup({
      ...args,
      ...this.getApiContext(),
    });
  }

  async getBusinessInfo(): Promise<GetAggregatedInfoResponse> {
    return getBusinessInfo(this.getApiContext());
  }

  async listResourcesByTagName(args: ListResourcesByTagNameArgs) {
    return listResourcesByTagName({
      ...args,
      ...this.getApiContext(),
    });
  }

  async queryBookings(args: QueryBookingsArgs) {
    return queryBookings({
      ...args,
      ...this.getApiContext(),
    });
  }

  async getListBookingFees(args: getListBookingFeesArgs) {
    return getListBookingFees({
      ...args,
      ...this.getApiContext(),
    });
  }

  async queryServices(args: QueryServicesArgs) {
    return queryServices({
      ...args,
      ...this.getApiContext(),
    });
  }

  async getGroupAllowedActions(args: GetGroupAllowedActionsArgs) {
    return getGroupAllowedActions({
      ...args,
      ...this.getApiContext(),
    });
  }

  async getEnrichedBookings(args: GetEnrichedBookingsArgs) {
    return getEnrichedBookings({
      ...args,
      ...this.getApiContext(),
    });
  }

  async getOrdersAndTransactions(args: GetOrdersAndTransactionsArgs) {
    return getOrdersAndTransactions({
      ...args,
      ...this.getApiContext(),
    });
  }

  async listTransactionsForMultipleOrders(
    args: ListTransactionsForMultipleOrdersArgs,
  ): Promise<ListTransactionsForMultipleOrdersResponse> {
    return listTransactionsForMultipleEcomOrders({
      ...args,
      ...this.getApiContext(),
    });
  }

  async queryOrders(args: QueryEcomOrdersArgs): Promise<QueryOrderResponse> {
    return queryEcomOrders({
      ...args,
      ...this.getApiContext(),
    });
  }

  isSSR() {
    return isSSR(this.getApiContext());
  }

  getEnvironmentModes() {
    return getEnvironmentModes({
      ...this.getApiContext(),
    });
  }

  logBi(args: LogBiArgs): Promise<void> {
    return logBi({
      ...args,
      ...this.getApiContext(),
    });
  }
}
