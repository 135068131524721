import { ApiContext } from '../../types';

export type EnvironmentModes = {
  isPreviewMode: boolean;
  isEditorMode: boolean;
  isSiteMode: boolean;
};

export const getEnvironmentModes = ({
  flowAPI,
}: ApiContext): EnvironmentModes => {
  const { controllerConfig } = flowAPI;
  const viewMode =
    controllerConfig?.wixCodeApi?.window?.viewMode?.toLowerCase();

  return {
    isPreviewMode: isPreviewMode(viewMode),
    isEditorMode: isEditorMode(viewMode),
    isSiteMode: isSiteMode(viewMode),
  };
};

const isPreviewMode = (viewMode: string) => {
  return viewMode === 'preview';
};

const isEditorMode = (viewMode: string) => {
  return viewMode === 'editor';
};

const isSiteMode = (viewMode: string) => {
  return viewMode === 'site';
};
